/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { Cache } from "aws-amplify";

var urlComponents = new URL( window.location.href );
var configToLoad = urlComponents.searchParams.get("pclt");
if( configToLoad )
{ 
	Cache.setItem( 'configToLoad', configToLoad ); 
}
else
{
	configToLoad = Cache.getItem( 'configToLoad' ); 
}
var awsmobile = {}
if( configToLoad )
{
	switch( configToLoad )
	{
		default:
		case '1hhk6uvi7lrkub9ecqnieuk7mj': // Generic Case
			awsmobile = {
				"aws_project_region": "us-east-1",
				"aws_cognito_identity_pool_id": "us-east-1:5913b796-8f7e-44f3-b7a4-1146f114e728",
				"aws_cognito_region": "us-east-1",
				"aws_user_pools_id": "us-east-1_ot8tXaaB4",
				"aws_user_pools_web_client_id": "1hhk6uvi7lrkub9ecqnieuk7mj", 
				"oauth": {
					"domain": "devstsi.controlcase.com",
					"scope": [
						"aws.cognito.signin.user.admin",
						"email",
						"openid",
						"phone",
						"profile"
					],
					"redirectSignIn": "https://devsts.controlcase.com/",
					"redirectSignOut": "https://devsts.controlcase.com/",
					"responseType": "code"
				},
				"federationTarget": "COGNITO_USER_POOLS"
			};
		break;
		
		case '4s978hb5uuducdec1um133dsth': // 1 - ControlCase IDP and Client User Sign In
			awsmobile = {
				"aws_project_region": "us-east-1",
				"aws_cognito_identity_pool_id": "us-east-1:5913b796-8f7e-44f3-b7a4-1146f114e728",
				"aws_cognito_region": "us-east-1",
				"aws_user_pools_id": "us-east-1_ot8tXaaB4",
				"aws_user_pools_web_client_id": "4s978hb5uuducdec1um133dsth", 
				"oauth": {
					"domain": "devstsi.controlcase.com",
					"scope": [
						"aws.cognito.signin.user.admin",
						"email",
						"openid",
						"phone",
						"profile"
					],
					"redirectSignIn": "https://devsts.controlcase.com/",
					"redirectSignOut": "https://devsts.controlcase.com/",
					"responseType": "code"
				},
				"federationTarget": "COGNITO_USER_POOLS"
			};
		break;
		
		case '6043ui6ld8lual3eamfmds57nv': // 2 - SCA Like Case with Client Certificate ( 2 IDPs )
			awsmobile = {
				"aws_project_region": "us-east-1",
				"aws_cognito_identity_pool_id": "us-east-1:5913b796-8f7e-44f3-b7a4-1146f114e728",
				"aws_cognito_region": "us-east-1",
				"aws_user_pools_id": "us-east-1_ot8tXaaB4",
				"aws_user_pools_web_client_id": "6043ui6ld8lual3eamfmds57nv", 
				"oauth": {
					"domain": "devstsi.controlcase.com",
					"scope": [
						"aws.cognito.signin.user.admin",
						"email",
						"openid",
						"phone",
						"profile"
					],
					"redirectSignIn": "https://devsts.controlcase.com/",
					"redirectSignOut": "https://devsts.controlcase.com/",
					"responseType": "code"
				},
				"federationTarget": "COGNITO_USER_POOLS"
			};
		break;
		
		case '7n79h9et5831m0fnfr4h15rjmv': // 3 - SCA Like Case without Client Certificate
			awsmobile = {
				"aws_project_region": "us-east-1",
				"aws_cognito_identity_pool_id": "us-east-1:5913b796-8f7e-44f3-b7a4-1146f114e728",
				"aws_cognito_region": "us-east-1",
				"aws_user_pools_id": "us-east-1_ot8tXaaB4",
				"aws_user_pools_web_client_id": "7n79h9et5831m0fnfr4h15rjmv", 
				"oauth": {
					"domain": "devstsi.controlcase.com",
					"scope": [
						"aws.cognito.signin.user.admin",
						"email",
						"openid",
						"phone",
						"profile"
					],
					"redirectSignIn": "https://devsts.controlcase.com/",
					"redirectSignOut": "https://devsts.controlcase.com/",
					"responseType": "code"
				},
				"federationTarget": "COGNITO_USER_POOLS"
			};
		break;
		
		case '7plqq1ffs28gjc00m2ea1d2slr': // 4 - Bestbuy like case with 2 FA
			awsmobile = {
				"aws_project_region": "us-east-1",
				"aws_cognito_identity_pool_id": "us-east-1:5913b796-8f7e-44f3-b7a4-1146f114e728",
				"aws_cognito_region": "us-east-1",
				"aws_user_pools_id": "us-east-1_ot8tXaaB4",
				"aws_user_pools_web_client_id": "7plqq1ffs28gjc00m2ea1d2slr", 
				"oauth": {
					"domain": "devstsi.controlcase.com",
					"scope": [
						"aws.cognito.signin.user.admin",
						"email",
						"openid",
						"phone",
						"profile"
					],
					"redirectSignIn": "https://devsts.controlcase.com/",
					"redirectSignOut": "https://devsts.controlcase.com/",
					"responseType": "code"
				},
				"federationTarget": "COGNITO_USER_POOLS"
			};
		break;
	}
}
export default awsmobile;
import React, { Component } from 'react';
import logo from './controlcase_logo.png';
import Amplify, { Auth, Cache } from "aws-amplify";
import awsExports from "./aws-exports";
import awsConfig from "./aws-config";
import {AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from "@aws-amplify/ui-react"; 
Amplify.configure(awsExports); 
Auth.configure( { oauth: awsConfig } )
class  OtherIdentities extends Component 
{
	constructor(props) {
		super(props);
		this.state = {
		  error: null,
		  isLoaded: false,
		  providers: [],
		  state:null
		};
		this.cac1 = null;
		this.cac2 = null;
		this.idpClientId = "7b3be9r5sd9ct2glplg2g0q2d5";
		this.portalState = null;
		this.portalClient = null;
	}
	
	componentDidMount() {  
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', 'pc': awsExports.aws_user_pools_id+'#~#'+awsExports.aws_user_pools_web_client_id }
		};
		
		var currentUrl = window.location.href; 
		var urlComponents = new URL(currentUrl);
		this.portalState = urlComponents.searchParams.get("pstate");
		if( this.portalState )
		{ 
			Cache.setItem( 'portalState', this.portalState );
			this.portalState = Cache.getItem( 'portalState' );
			awsConfig.state = this.portalState;
			Auth.configure( { oauth: awsConfig } )
		}
		else
		{
			this.portalState = Cache.getItem( 'portalState' );
		}
		
		this.portalClient = urlComponents.searchParams.get("pclt");
		if( this.portalClient )
		{ 
			Cache.setItem( 'portalClient', this.portalClient );
			this.portalClient = Cache.getItem( 'portalClient' );
		}
		else
		{
			this.portalClient = Cache.getItem( 'portalClient' );	
		}
		
		var secureTokenStatus = urlComponents.searchParams.get("sts"); 
	 
		if( secureTokenStatus !== "" && secureTokenStatus === "cln" )
		{ 
			Auth.signOut( { global: true } );   
			var appDetails = this.portalState.split(".");
			var redirectUrl = "https://www.controlcase.com/";
			if ( appDetails[2] === "grc" && appDetails[3] === "regular" ) 
				redirectUrl = "https://uatportal.controlcase.com/"+appDetails[4]+"/index.php"; 
			else if( appDetails[2] === "grc"& appDetails[3] === "caas" )
				redirectUrl = "https://uatcaas.controlcase.com/"+appDetails[4]+"/index.php"; 
			window.location.href = redirectUrl;			
		}
		else
		{ 
			fetch('https://szum8hf6x1.execute-api.us-east-1.amazonaws.com/d/ci/gsi', requestOptions )
			.then(res => res.json())
			.then(result => {
				this.setState({
					isLoaded: true,
					providers: result
				}); 
			});
		}
	}

	componentDidUpdate()
	{ 
	}
	
	componentWillUnmount()
	{  
	}
	
	useEffect()
	{ 
	}
	
	redirectUser( state, data )
	{  
		if( state !== 'signin' )
		{
			var currentUrl = window.location.href; 
			var urlComponents = new URL(currentUrl);
			this.portalState = urlComponents.searchParams.get("pstate");
		
			var redirectUrl = "https://www.controlcase.com/";
			
			if( this.portalState )
			{ 
				Cache.setItem( 'portalState', this.portalState );
			}
			else
			{
				this.portalState = Cache.getItem( 'portalState' );
			}
						
			if( this.portalState )
			{
				var appDetails = this.portalState.split(".");				
				if ( appDetails[2] === "grc" && appDetails[3] === "regular" ) 
					redirectUrl = "https://uatportal.controlcase.com/"+appDetails[4]+"/index.php"; 
				else if( appDetails[2] === "grc"& appDetails[3] === "caas" )
					redirectUrl = "https://uatcaas.controlcase.com/"+appDetails[4]+"/index.php"; 
			}
 
			Auth.currentSession().then(res=>{ 
				let refreshToken = res.getRefreshToken();
				document.getElementById( "cac1" ).value = refreshToken.token;
				document.getElementById( "appLink" ).action = redirectUrl;
				this.cac1 = refreshToken.token;
				Auth.currentAuthenticatedUser().then((user) => { 
					document.getElementById( "cac2" ).value = this.cac2 = user.attributes.email;				
					setTimeout(function(){ 
						document.getElementById("appLink").submit();	
					}, 3000);
				});
			});
		}
	}
	
	render() 
	{
		const blockStyle = {
			position: "relative",
			marginBottom: "var(--margin-bottom, 20px)",
			backgroundColor: "var(--background-color)",
			padding: "var(--padding, 35px 40px)",
			textAlign: "left",
			display: "inline-block",
			borderRadius: "var(--border-radius, 6px)",
			WebkitBoxShadow: "var(--box-shadow, 1px 1px 4px 0 rgba(0, 0, 0, 0.15))",
			boxShadow: "var(--box-shadow, 1px 1px 4px 0 rgba(0, 0, 0, 0.15))",
			WebkitBoxSizing: "border-box",
			boxSizing: "border-box",
			fontFamily: "var(--font-family)",
			width: "100%",
			minWidth: "var(--min-width, 20rem)"
		};
		
		const { error, isLoaded, providers } = this.state; 
		if (error)
		{
			return 	<amplify-authenticator className="hydrated">
						<div className="App" slot="sign-in"  >
							<table style={blockStyle}  >
								<tbody> 
									<tr><td align="center" ><img src={logo} alt="ControlCase" /></td></tr>
									<tr><td align="center" >&nbsp;</td></tr>
									<tr><td align="center" >&nbsp;</td></tr>
									<tr><td align="center" >Something went wrong...<br/>{error.message}</td></tr>
								</tbody>
							</table>
						</div>
					</amplify-authenticator>; 
		}
		else if (!isLoaded)
		{
			return 	<amplify-authenticator className="hydrated">
						<div className="App" slot="sign-in"  >
							<table style={blockStyle}  >
								<tbody> 
									<tr><td align="center" ><img src={logo} alt="ControlCase" /></td></tr>
									<tr><td align="center" >&nbsp;</td></tr>									
									<tr><td align="center" >Loading...<br/>Please wait</td></tr>
								</tbody>
							</table>
						</div>
					</amplify-authenticator>; 
		}
		else
		{ 
			const signInForm = []; 
			var customCSS = "none"; 
			var customPadding = "0px 40px 0px 40px"; 
			var borderRight = "none"; 			
			
			console.log( this.portalState );
			console.log( this.portalClient );
			
			// this.portalState = "";
			
			if( isLoaded && this.portalState && this.portalClient )
			{
				for ( const [ index, currentProviderName ] of providers.data.SupportedIdentityProviders.entries() )
				{ 
					if( currentProviderName === "COGNITO" )
					{ 
						customCSS = "flex";			
						customPadding = "0px 40px 0px 40px"; 
						borderRight = "1px solid";						
						borderRight = "none";						
					}
					
					if( currentProviderName !== "COGNITO" )
					{
						var stringPresent = currentProviderName.search(/controlcase/i);
						
						if( stringPresent > -1 )
						{
							signInForm.push( 
								<section key={index} className="section" style={{padding: customPadding }} >  
									<div className="form-section-header">
										<h3 className="header" style={{marginBlockStart:"0px", textAlign:'center'}}>For ControlCase Employees Only</h3> 
									</div>
									<div className="sign-in-form-footer"> 
										<amplify-button className="hydrated" type="submit" style={{padding:"0px", width: "92%", height:"40px"}} onClick={() => Auth.federatedSignIn( {provider: currentProviderName, state: this.portalState } ) } >{currentProviderName}</amplify-button>  
									</div>
									<div>&nbsp;</div>
								</section>
							);
						}
						else
						{
							signInForm.unshift(
								<section key={index} className="section" style={{padding: customPadding }} > 
									<div className="form-section-header">
										<h3 className="header" style={{marginBlockStart:"0px", textAlign:'center'}}>Sign in to ControlCase SkyCAM Portal</h3> 
									</div>
									<div className="sign-in-form-footer"> 
										<amplify-button className="hydrated" type="submit" style={{padding:"0px", minWidth: "153px", height:"40px"}} onClick={() => Auth.federatedSignIn( { provider: currentProviderName, state: this.portalState } ) } >{currentProviderName}</amplify-button>  
									</div>
									<div>&nbsp;</div>
								</section>
							);
						}
					}
				}
				
				return (
						<>
						<AmplifyAuthenticator   usernameAlias="email" key="Authenticator" 
						handleAuthStateChange={(state, data) => {  
							this.redirectUser(state, data);
						}}
						>		
							<div className="App" slot="sign-in"  style={{paddingTop:"5px"}}>
								<table style={blockStyle}>
									<tbody> 
										<tr>
											<td colSpan="2" align="center" >
												<img src={logo} alt="ControlCase LLC" title="ControlCase LLC"  style={{padding: customPadding}}/>  
											</td>
										</tr> 
										<tr>
											<td style={{borderRight:borderRight}}>
												{signInForm}
											</td>
											<td style={{display: customCSS }} >
												<AmplifySignIn hideDefault headerText="Sign in to ControlCase SkyCAM Portal" slot="sign-in" hideSignUp usernameAlias="email" style={{boxShadow:"none"}} ><div slot="federated-buttons"></div></AmplifySignIn>
											</td>
										</tr>
									</tbody>
								</table>								
							</div>
							<div id="customSignOut" className="App" style={{padding: "5% 35%", width:"35%"}} >
								<table style={blockStyle}>
									<tbody> 
										<tr>
											<td align="center" >
												<img src={logo} alt="ControlCase" style={{padding: customPadding}}/>  
											</td>
										</tr>
										<tr>
											<td align="center" >&nbsp;</td>
										</tr>
										<tr>
											<td align="center" >&nbsp;</td>
										</tr>
										<tr>
											<td align="center" >
												<form method="post"  id="appLink" >
													<input type="textaraea" style={{ display:"none" }} name="cac1" id="cac1" />
													<input type="text" style={{ display:"none" }}  name="cac2" id="cac2" />
													<input type="submit" style={{ display:"none" }}  value="Go To App"/>
													<div>
													Redirecting...
													<br/><br/>
													Please wait.
													</div>
												</form>
											</td>
										</tr>
										<tr>
											<td align="center" >&nbsp;</td>
										</tr>
										<tr>
											<td align="center" >&nbsp;</td>
										</tr>
										<tr>
											<td align="center" >
												<AmplifySignOut 
												handleAuthStateChange={(state, data) => { 
													this.redirectUser(state, data);
												}}
												></AmplifySignOut>
											</td>
										</tr>
									</tbody>
								</table>	
							</div>
						</AmplifyAuthenticator> 
						</>
				);
			}
			else
			{
				return(
					<amplify-authenticator className="hydrated">
						<div className="App" slot="sign-in"  >
							<table style={blockStyle}  >
								<tbody> 
									<tr><td align="center" ><img src={logo} alt="ControlCase" /></td></tr>
									<tr><td align="center" >&nbsp;</td></tr>
									<tr><td align="center" >&nbsp;</td></tr>
									<tr><td align="center" >Invalid Request, Unable to process the reqeust.</td></tr>
								</tbody>
							</table>
						</div>
					</amplify-authenticator>
				);
			}	
		} 
	}
}

function App() {
  return ( <OtherIdentities></OtherIdentities> );
}

export default App;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { Cache } from "aws-amplify";

var urlComponents = new URL( window.location.href );
var configToLoad = urlComponents.searchParams.get("pclt");
if( configToLoad )
{ 
	Cache.setItem( 'configToLoad', configToLoad ); 
}
else
{
	configToLoad = Cache.getItem( 'configToLoad' ); 
}

var awsauth = {}

if( configToLoad )
{
	switch( configToLoad )
	{
		default:
		case '1hhk6uvi7lrkub9ecqnieuk7mj': // Generic Case
		case '4s978hb5uuducdec1um133dsth': // 1 - ControlCase IDP and Client User Sign In
		case '6043ui6ld8lual3eamfmds57nv': // 2 - SCA Like Case with Client Certificate ( 2 IDPs )
		case '7n79h9et5831m0fnfr4h15rjmv': // 3 - SCA Like Case without Client Certificate
		case '7plqq1ffs28gjc00m2ea1d2slr': // 4 - Bestbuy like case with 2 FA		
			awsauth = {
				"domain": "devstsi.controlcase.com",
				"scope": [
					"aws.cognito.signin.user.admin",
					"email",
					"openid",
					"phone",
					"profile"
				],
				"redirectSignIn": "https://devsts.controlcase.com/",
				"redirectSignOut": "https://devsts.controlcase.com/",
				"responseType": "code"
			};
		break;
	}
}
export default awsauth;